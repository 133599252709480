import './App.svelte.css';
/* src\App.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { RouterView, Link } from "../_snowpack/pkg/@bjornlu/svelte-router.js";
import axios from "../_snowpack/pkg/axios.js";
import "./w3.css";
import "./bootstrap.min.css";

function create_fragment(ctx) {
	let div2;
	let div0;
	let t9;
	let routerview;
	let t10;
	let div1;
	let current;
	routerview = new RouterView({});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			div0.innerHTML = `<button class="btn btn-outline-dark uu ff svelte-vhbhgk"><a href="/" class="svelte-vhbhgk">Pedal Bins</a></button> 
    <button class="btn btn-outline-dark uu svelte-vhbhgk"><a href="/plain" class="svelte-vhbhgk">Plain Bins</a></button> 
    <button class="btn btn-outline-dark uu svelte-vhbhgk"><a href="/pawali" class="svelte-vhbhgk">Pawalis</a></button> 
    <button class="btn btn-outline-dark uu svelte-vhbhgk"><a href="/ration" class="svelte-vhbhgk">Rashan Dabas</a></button> 
    <button class="btn btn-outline-dark uu ii svelte-vhbhgk"><a href="/balti" class="svelte-vhbhgk">Buckets</a></button>`;

			t9 = space();
			create_component(routerview.$$.fragment);
			t10 = space();
			div1 = element("div");
			div1.innerHTML = `<span class="dd svelte-vhbhgk">G &amp; G Steels</span>`;
			attr(div0, "class", "bb w3-top svelte-vhbhgk");
			attr(div1, "class", "aa cc svelte-vhbhgk");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t9);
			mount_component(routerview, div2, null);
			append(div2, t10);
			append(div2, div1);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(routerview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(routerview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_component(routerview);
		}
	};
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default App;