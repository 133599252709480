import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import { initPathRouter } from '../_snowpack/pkg/@bjornlu/svelte-router.js';
import App from './App.svelte.js';
import Home from './Pages/Pedal.svelte.js';
import Products from './Pages/Plain.svelte.js';
import Balti from './Pages/Balti.svelte.js';
import Pawali from './Pages/Pawali.svelte.js';
import Ration from './Pages/Ration.svelte.js';

initPathRouter([
  { path: '/', component: Home },
  { path: '/plain', component: Products },
  { path: '/balti', component: Balti },
  { path: '/pawali', component: Pawali },
  { path: '/ration', component: Ration }
  ])


let app = new App({
  target: document.body,
});

export default app;

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/concepts/hot-module-replacement
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
  undefined /* [snowpack] import.meta.hot */ .dispose(() => {
    app.$destroy();
  });
}

