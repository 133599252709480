import './Pawali.svelte.css';
/* src\Pages\Pawali.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import axios from "../../_snowpack/pkg/axios.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (61:1) {#each yy as item}
function create_each_block(ctx) {
	let div2;
	let img;
	let img_src_value;
	let t0;
	let div1;
	let h2;
	let t1_value = /*item*/ ctx[2].gsx$product.$t + "";
	let t1;
	let t2;
	let div0;
	let table;
	let tr0;
	let td0;
	let td1;
	let t4_value = /*item*/ ctx[2].gsx$diameterinch.$t + "";
	let t4;
	let t5;
	let t6;
	let tr1;
	let td2;
	let td3;
	let t8_value = /*item*/ ctx[2].gsx$heightinch.$t + "";
	let t8;
	let t9;
	let t10;
	let tr2;
	let td4;
	let t12;
	let td5;
	let t13_value = /*item*/ ctx[2].gsx$widthinch.$t + "";
	let t13;
	let t14;
	let t15;
	let tr3;
	let td6;
	let t17;
	let td7;
	let t18_value = /*item*/ ctx[2].gsx$thicknessmm.$t + "";
	let t18;
	let t19;
	let t20;
	let tr4;
	let td8;
	let t22;
	let td9;
	let t23_value = /*item*/ ctx[2].gsx$basecircleinch.$t + "";
	let t23;
	let t24;
	let t25;
	let tr5;
	let td10;
	let t27;
	let td11;
	let t28_value = /*item*/ ctx[2].gsx$weightkg.$t + "";
	let t28;
	let t29;
	let t30;

	return {
		c() {
			div2 = element("div");
			img = element("img");
			t0 = space();
			div1 = element("div");
			h2 = element("h2");
			t1 = text(t1_value);
			t2 = space();
			div0 = element("div");
			table = element("table");
			tr0 = element("tr");
			td0 = element("td");
			td0.textContent = "Diameter";
			td1 = element("td");
			t4 = text(t4_value);
			t5 = text(" Inch");
			t6 = space();
			tr1 = element("tr");
			td2 = element("td");
			td2.textContent = "Height";
			td3 = element("td");
			t8 = text(t8_value);
			t9 = text(" Inch");
			t10 = space();
			tr2 = element("tr");
			td4 = element("td");
			td4.textContent = "Width";
			t12 = space();
			td5 = element("td");
			t13 = text(t13_value);
			t14 = text(" Inch");
			t15 = space();
			tr3 = element("tr");
			td6 = element("td");
			td6.textContent = "Thickness";
			t17 = space();
			td7 = element("td");
			t18 = text(t18_value);
			t19 = text(" mm");
			t20 = space();
			tr4 = element("tr");
			td8 = element("td");
			td8.textContent = "Circle";
			t22 = space();
			td9 = element("td");
			t23 = text(t23_value);
			t24 = text(" Inch");
			t25 = space();
			tr5 = element("tr");
			td10 = element("td");
			td10.textContent = "Weight";
			t27 = space();
			td11 = element("td");
			t28 = text(t28_value);
			t29 = text(" Kg");
			t30 = space();
			if (img.src !== (img_src_value = "https://res.cloudinary.com/kanik/image/upload/v1626675771/Bawana/pawali-250x250_qodr30.jpg")) attr(img, "src", img_src_value);
			attr(img, "alt", "Alps");
			attr(img, "class", "zz svelte-achmx7");
			attr(h2, "class", "cc svelte-achmx7");
			set_style(td0, "width", "30%");
			attr(td0, "class", "ff svelte-achmx7");
			attr(td1, "class", "ee ff svelte-achmx7");
			set_style(td2, "width", "30%");
			attr(td2, "class", "ff svelte-achmx7");
			attr(td3, "class", "ee ff svelte-achmx7");
			attr(td4, "class", "ff svelte-achmx7");
			attr(td5, "class", "ee ff svelte-achmx7");
			attr(td6, "class", "ff svelte-achmx7");
			attr(td7, "class", "ee ff svelte-achmx7");
			attr(td8, "class", "ff svelte-achmx7");
			attr(td9, "class", "ee ff svelte-achmx7");
			attr(td10, "class", "ff svelte-achmx7");
			attr(td11, "class", "ee ff svelte-achmx7");
			attr(table, "class", "w3-table w3-border gg svelte-achmx7");
			attr(div0, "class", "dd svelte-achmx7");
			attr(div1, "class", "w3-container w3-center");
			attr(div2, "class", "aa w3-card-4 svelte-achmx7");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, img);
			append(div2, t0);
			append(div2, div1);
			append(div1, h2);
			append(h2, t1);
			append(div1, t2);
			append(div1, div0);
			append(div0, table);
			append(table, tr0);
			append(tr0, td0);
			append(tr0, td1);
			append(td1, t4);
			append(td1, t5);
			append(table, t6);
			append(table, tr1);
			append(tr1, td2);
			append(tr1, td3);
			append(td3, t8);
			append(td3, t9);
			append(table, t10);
			append(table, tr2);
			append(tr2, td4);
			append(tr2, t12);
			append(tr2, td5);
			append(td5, t13);
			append(td5, t14);
			append(table, t15);
			append(table, tr3);
			append(tr3, td6);
			append(tr3, t17);
			append(tr3, td7);
			append(td7, t18);
			append(td7, t19);
			append(table, t20);
			append(table, tr4);
			append(tr4, td8);
			append(tr4, t22);
			append(tr4, td9);
			append(td9, t23);
			append(td9, t24);
			append(table, t25);
			append(table, tr5);
			append(tr5, td10);
			append(tr5, t27);
			append(tr5, td11);
			append(td11, t28);
			append(td11, t29);
			append(div2, t30);
		},
		p(ctx, dirty) {
			if (dirty & /*yy*/ 1 && t1_value !== (t1_value = /*item*/ ctx[2].gsx$product.$t + "")) set_data(t1, t1_value);
			if (dirty & /*yy*/ 1 && t4_value !== (t4_value = /*item*/ ctx[2].gsx$diameterinch.$t + "")) set_data(t4, t4_value);
			if (dirty & /*yy*/ 1 && t8_value !== (t8_value = /*item*/ ctx[2].gsx$heightinch.$t + "")) set_data(t8, t8_value);
			if (dirty & /*yy*/ 1 && t13_value !== (t13_value = /*item*/ ctx[2].gsx$widthinch.$t + "")) set_data(t13, t13_value);
			if (dirty & /*yy*/ 1 && t18_value !== (t18_value = /*item*/ ctx[2].gsx$thicknessmm.$t + "")) set_data(t18, t18_value);
			if (dirty & /*yy*/ 1 && t23_value !== (t23_value = /*item*/ ctx[2].gsx$basecircleinch.$t + "")) set_data(t23, t23_value);
			if (dirty & /*yy*/ 1 && t28_value !== (t28_value = /*item*/ ctx[2].gsx$weightkg.$t + "")) set_data(t28, t28_value);
		},
		d(detaching) {
			if (detaching) detach(div2);
		}
	};
}

function create_fragment(ctx) {
	let h1;
	let t1;
	let div;
	let each_value = /*yy*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Pawalis";
			t1 = space();
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(h1, "class", "jj svelte-achmx7");
			attr(div, "class", "bb svelte-achmx7");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*yy*/ 1) {
				each_value = /*yy*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	var yy = [];

	function getData() {
		axios.get("https://spreadsheets.google.com/feeds/list/1H4UOgrGbdjq866V_w6jIqr55sBh01c4SSOcelddFwtQ/od6/public/values?alt=json").then(function (response) {
			$$invalidate(0, yy = response.data.feed.entry);
			console.log(yy);
		});
	}

	getData();
	return [yy];
}

class Pawali extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Pawali;